<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    :width="size.width"
    :height="size.height"
    viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve"
  >

    <ellipse class="st0" cx="100" cy="100.501" rx="99.75" ry="100"/>
    <text x="50%" y="54%" dominant-baseline="middle" text-anchor="middle" class="st1 st2"> {{ title }} </text>
</svg>

</template>

<script>
export default {
  props: {
    size: {
      type: Object,
      default: {
        width: '100%',
        height: '100%'
      }
    },
    title: {
      type: Object,
      default: () => {
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.st0 {
  fill: rgb(221, 221, 221);;
}

.st1 {
  font-family: Avenir;
  fill: #4D4F5C;
}

.st2 {
  font-size: 77.5793px;
}
</style>
